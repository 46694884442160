import classNames from 'classnames';
import styles from 'containers/Clubs/PostCreator.module.scss';
import { RichTextData } from 'containers/ReactionBar/Comments';
import {
  ClubPostPartsFragment,
  SearchProfilesDocument,
  SearchProfilesQuery,
  SearchProfilesQueryVariables,
  usePostToClubMutation,
  useUpdateClubPostMutation,
} from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import useImperativeCallQuery from 'hooks/useImperativeCallQuery';
import useMixpanel from 'hooks/useMixpanel';
import { useState } from 'react';
import { Avatar, Button } from 'ui/generic';
import { RichTextEditor } from 'ui/specific/richText/RichTextEditor';
import getApolloClient from 'utils/getApolloClient';

type Props = {
  clubId: string;
  clubHandle: string;
  initialData?: ClubPostPartsFragment;
  placeholder?: string;
};

const PostCreatorContainer = ({
  clubId,
  clubHandle,
  initialData,
  placeholder,
}: Props): JSX.Element | null => {
  const [postData, setPostData] = useState<RichTextData | null>(null);
  const [submitted, setSubmitted] = useState(false);
  const [hideLinkPreview, setHideLinkPreview] = useState(initialData?.hideLinkPreview ?? false);
  const { trackEvent } = useMixpanel();
  const { profile } = useAuthContext();
  const [postToClub, { loading: posting }] = usePostToClubMutation();
  const [updateClubPost, { loading: updatingPost }] = useUpdateClubPostMutation();

  const searchProfiles = useImperativeCallQuery<SearchProfilesQuery, SearchProfilesQueryVariables>(
    SearchProfilesDocument
  );

  if (!profile) return null;

  function invalidateFeedCache() {
    const client = getApolloClient();
    client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'clubTimeline' });
    client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'posts' });
  }

  const getMentionSuggestions = async (query: string) => {
    const searchQuery = query.toLowerCase();
    if (!searchQuery) return [];
    const res = await searchProfiles({ query: searchQuery });
    if (res?.data?.searchProfiles) {
      return res.data?.searchProfiles;
    } else {
      return [];
    }
  };

  async function handleSubmit() {
    if (posting || updatingPost) return;
    if (postData && postData.text.length > 0) {
      const json = JSON.stringify({ text: postData.text, textJson: postData.textJson });
      if (initialData) {
        await updateClubPost({
          variables: { body: json, clubPostId: initialData.id, hideLinkPreview },
        });
      } else {
        await postToClub({
          variables: {
            body: json,
            clubId: clubId,
            hideLinkPreview,
          },
        });
      }
      trackEvent('posted', { handle: clubHandle });
      setPostData(null);
      setSubmitted(true);

      setSubmitted(false);
      if (!initialData) invalidateFeedCache();
    }
  }

  return (
    <>
      {initialData ? (
        <RichTextEditor
          getSuggestions={getMentionSuggestions}
          placeholder={placeholder ?? 'Share your thoughts...'}
          setValue={(value: RichTextData) => setPostData(value)}
          submitted={submitted}
          content={initialData.body.textJson ? initialData.body.textJson : initialData.body.text}
          linkPreviewState={{ hideLinkPreview, setHideLinkPreview }}
        />
      ) : (
        <div className={styles.container}>
          <div className={classNames('pt-1', styles.left)}>
            <Avatar profile={profile} width={40} />
          </div>
          <div className={styles.right}>
            <RichTextEditor
              getSuggestions={getMentionSuggestions}
              placeholder={placeholder ?? 'Share your thoughts...'}
              setValue={(value: RichTextData) => setPostData(value)}
              submitted={submitted}
              linkPreviewState={{ hideLinkPreview, setHideLinkPreview }}
            />
          </div>
        </div>
      )}

      {postData && (
        <div className={classNames(styles.sendButton)}>
          <Button disabled={postData.text.length < 1} variant="focus" onClick={handleSubmit}>
            {initialData ? 'Save' : 'Post'}
          </Button>
        </div>
      )}
    </>
  );
};

export default PostCreatorContainer;
