import DataLoader from 'dataloader';
import {
  UnreadClubPostsByPostIdsDocument,
  UnreadClubPostsByPostIdsQuery,
  UnreadClubPostsByPostIdsQueryVariables,
} from 'generated/graphql';
import getApolloClient from 'utils/getApolloClient';
import create from 'zustand';

export const clubPostUnreadLoader = new DataLoader(
  async (keys: readonly string[]): Promise<boolean[]> => {
    const client = getApolloClient();

    const { data } = await client.query<
      UnreadClubPostsByPostIdsQuery,
      UnreadClubPostsByPostIdsQueryVariables
    >({
      query: UnreadClubPostsByPostIdsDocument,
      variables: { clubPostIds: [...keys] },
      fetchPolicy: 'no-cache',
    });

    return data.unreadClubPostsByPostIds || [];
  },
  {
    batch: true,
    cache: true,
    cacheKeyFn: (key: string): string => `unreadClubPost:${key}`,
  }
);

type State = {
  unreads: Record<string, boolean>;
  fetchUnread: (clubPostIds: string) => void;
  clearUnread: (clubPostIds: string) => void;
  clearAllUnread: () => void;
};

export const useClubPostUnread = create<State>((set, get) => ({
  unreads: {},
  fetchUnread: async (id: string) => {
    if (get().unreads[id]) return;
    const unread = await clubPostUnreadLoader.load(id);
    set((state) => ({ ...state, unreads: { ...state.unreads, [id]: unread } }));
    return false;
  },
  clearUnread: (id: string) => {
    if (!get().unreads[id]) return;
    clubPostUnreadLoader.clear(id);
    const unreads = { ...get().unreads };
    delete unreads[id];
    set((state) => ({ ...state, unreads }));
  },
  clearAllUnread: () => {
    clubPostUnreadLoader.clearAll();
    set((state) => ({ ...state, unreads: {} }));
  },
}));
