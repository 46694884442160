import classNames from 'classnames';
import { ConditionalWrapper } from 'components/layout/ConditionalWrapper';
import { UserInClubInlineContainer } from 'containers/inline/UserInClubInlineContainer';
import { UserInlineContainer } from 'containers/inline/UserInlineContainer';
import { useClubPostUnread } from 'dataloaders/clubPostUnreadLoader';
import { dayjs } from 'date-utils';
import { ClubPartsFragment, ClubPostFullFragment } from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import Link from 'next/link';
import { ElementType, useEffect } from 'react';
import { LinkText } from 'ui/generic';
import { RichTextViewer } from 'ui/specific/richText/RichTextViewer';
import { routes } from 'utils/routes';
import { notEmpty } from 'utils/typeGuards';
import styles from './ClubPost.module.scss';
import PostCreatorContainer from './PostCreator';

type Props = {
  post: ClubPostFullFragment;
  clubHandle: string;
  withLinkPreview?: boolean;
  avatarSticker?: JSX.Element;
  editMode?: boolean;
  withoutHeader?: boolean;
  withBottomContent?: string;
  asLink?: boolean;
  withClub?: ClubPartsFragment;
};

export const ClubPost = ({
  post,
  clubHandle,
  withLinkPreview,
  editMode,
  withoutHeader,
  avatarSticker,
  withBottomContent,
  asLink,
  withClub,
}: Props) => {
  const Comp: ElementType = asLink ? 'a' : 'div';

  const { isSignedIn } = useAuthContext();
  const { unreads, fetchUnread, clearUnread } = useClubPostUnread();
  const unread = unreads[post.id];

  useEffect(() => {
    if (!isSignedIn) return;
    fetchUnread(post.id);
  }, [isSignedIn]);

  return (
    <div className={styles.container}>
      <ConditionalWrapper
        condition={!!asLink}
        wrapper={(children) => (
          <Link href={routes.clubPost} as={`/club/${clubHandle}/post/${post.id}`}>
            {children}
          </Link>
        )}
      >
        <Comp
          className={classNames(styles.link, {
            [styles.unreadLink]: unread && !post.commentCount,
          })}
          onClick={() => {
            if (unread === true) {
              setTimeout(() => {
                clearUnread(post.id);
              }, 2000);
            }
          }}
        >
          {unread === true && (
            <div className={styles.unread}>
              New <div className={styles.unreadDot} />
            </div>
          )}
          {!withoutHeader && (
            <div className={styles.header}>
              {withClub ? (
                <UserInClubInlineContainer
                  profile={post.profile}
                  club={withClub}
                  bottomContent={withBottomContent || dayjs(post.createdAt).fromNow()}
                  avatarSticker={avatarSticker}
                />
              ) : (
                <UserInlineContainer
                  profile={post.profile}
                  bottomContent={withBottomContent || dayjs(post.createdAt).fromNow()}
                  avatarSticker={avatarSticker}
                  link={!asLink} // If the whole post is a link, dont make the UserInline a link
                />
              )}
            </div>
          )}
          <div className={styles.content}>
            {editMode ? (
              <PostCreatorContainer initialData={post} clubId={post.clubId} clubHandle={clubHandle} />
            ) : (
              <>
                {post.body.textJson ? (
                  <RichTextViewer
                    content={post.body.textJson}
                    hideLinkPreview={withLinkPreview ? false : undefined}
                  />
                ) : (
                  <LinkText accent={true}>{post.body.text || ''}</LinkText>
                )}
              </>
            )}
          </div>
          {notEmpty(post.commentCount) && post.commentCount > 0 && (
            <div className={styles.commentsCount}>
              {post.commentCount} comment{post.commentCount > 1 ? 's' : ''}
            </div>
          )}
        </Comp>
      </ConditionalWrapper>
    </div>
  );
};
