import { PatronBadgeSmall } from 'components/badges/PatronBadge';
import { useIsSupporter } from 'hooks/useIsSupporter';
import { UserInClubInline, UserInClubInlineProps } from 'ui/specific/inline/UserInClubInline';

export const UserInClubInlineContainer: React.FC<UserInClubInlineProps> = ({ avatarSticker, ...props }) => {
  const isSupporter = useIsSupporter({ profileId: props.profile.id });

  if (isSupporter && !avatarSticker) {
    avatarSticker = <PatronBadgeSmall />;
  }

  return <UserInClubInline avatarSticker={avatarSticker} {...props} />;
};
