import { ClubPartsFragment, ProfilePartsFragment } from 'generated/graphql';
import Link from 'next/link';
import { AvatarLink } from 'ui/generic';
import { Inline } from './Inline';
import styles from './UserInClubInline.module.scss';

type Props = {
  profile: ProfilePartsFragment;
  avatarSticker?: JSX.Element;
  bottomContent?: string;
  withLinks?: boolean;
  club: ClubPartsFragment;
};

export const UserInClubInline = ({
  profile,
  club,
  avatarSticker,
  bottomContent,
  withLinks,
  ...rest
}: Props & { club: ClubPartsFragment }): JSX.Element => {
  return (
    <Inline
      left={<AvatarLink width={40} profile={profile} avatarSticker={avatarSticker} />}
      top={
        withLinks ? (
          <>
            <Link href="/[handle]" as={`/${profile.handle}`}>
              <a className={styles.links}>{profile.name}</a>
            </Link>{' '}
            in{' '}
            <Link href="/club/[clubHandle]" as={`/club/${club.handle}`}>
              <a className={styles.links}>{club.name}</a>
            </Link>
          </>
        ) : (
          <>
            <strong>{profile.name}</strong> in <strong>{club.name}</strong>
          </>
        )
      }
      bottom={bottomContent}
      {...rest}
    />
  );
};

export type UserInClubInlineProps = Props;
